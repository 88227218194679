// node modules
import React from 'react'
import { graphql, PageProps } from 'gatsby'

// layout
import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// hooks
import usePopup from 'hooks/usePopup'

// components
import Hero from 'components/pages/downloads/hero'
import Breadcrumbs from 'components/shared/breadcrumbs'
import QA from 'components/pages/questions-and-answers/qa'

const NajczesciejZadawanePytania: React.FC<
  PageProps<Queries.NajczesciejZadawanePytaniaPageQuery>
> = ({ data }) => {
  const PAGE = data.wpPage?.faq
  const PAGE_SEO = data.wpPage?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const QUESTIONS_AND_ANSWERS = {
    ATV: PAGE?.faqAtvQuestions?.map((question) => ({
      question: question?.faqAtvQuestionsSingle!,
      answer: question?.faqAtvQuestionsAnswer!,
    }))!,
    UTV: PAGE?.faqUtvQuestions?.map((question) => ({
      question: question?.faqUtvQuestionsSingle!,
      answer: question?.faqUtvQuestionsAnswer!,
    }))!,
    SSV: PAGE?.faqSsvQuestions?.map((question) => ({
      question: question?.faqSsvQuestionsSingle!,
      answer: question?.faqSsvQuestionsAnswer!,
    }))!,
    Motocykle: PAGE?.faqMotocycleQuestions?.map((question) => ({
      question: question?.faqMotocycleQuestionsSingle!,
      answer: question?.faqMotocycleQuestionsAnswer!,
    }))!,
  }

  const POPUP = {
    image: {
      src: PAGE?.popupImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE?.popupImg?.altText ?? '',
    },
    delay: PAGE?.popupDelay,
    link: PAGE?.popupLink ?? null,
    visibleOnMobile: PAGE?.popupVisibleOnMobile ?? false,
  }

  usePopup(POPUP)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Hero
        heading={PAGE?.faqBannerTitle!}
        image={{
          src: PAGE?.faqBannerImg?.localFile?.childImageSharp?.gatsbyImageData!,
          alt: PAGE?.faqBannerImg?.altText!,
        }}
      />
      <Breadcrumbs crumbs={[{ name: data?.wpPage?.title! }]} />
      <QA qa={QUESTIONS_AND_ANSWERS} />
    </Layout>
  )
}

export default NajczesciejZadawanePytania

export const query = graphql`
  query NajczesciejZadawanePytaniaPage {
    wpPage(slug: { regex: "/faq/" }) {
      title
      faq {
        faqAtvQuestions {
          faqAtvQuestionsSingle
          faqAtvQuestionsAnswer
        }
        faqUtvQuestions {
          faqUtvQuestionsSingle
          faqUtvQuestionsAnswer
        }
        faqSsvQuestions {
          faqSsvQuestionsSingle
          faqSsvQuestionsAnswer
        }
        faqMotocycleQuestions {
          faqMotocycleQuestionsSingle
          faqMotocycleQuestionsAnswer
        }
        faqBannerTitle
        faqBannerImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                breakpoints: [1280, 1920]
              )
            }
          }
        }
        popupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        popupDelay
        popupLink
        popupVisibleOnMobile
      }
      seo {
        ...WpSEO
      }
    }
  }
`

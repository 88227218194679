import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import ButtonWithIcon from 'components/shared/button-with-icon'
import Icon from 'components/shared/icon'

import media from 'styles/media'

import useBreakpoint from 'hooks/useBreakpoint'

import arrowIcon from 'assets/icons/arrow-right-black.svg'

const Wrapper = styled.div`
  padding: 50px 0 100px 0;
`

const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  button {
    width: 100%;
  }

  ${media.lg.min} {
    flex-direction: row;
    gap: 10px;
  }
`

const ButtonWrapper = styled.div<{ active: boolean }>`
  width: 100%;

  ${media.lg.max} {
    &:not(&:first-of-type) {
      margin-top: 10px;
    }
  }

  ${({ active }) =>
    active &&
    css`
      button {
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.black};

        img {
          filter: invert();
        }
      }
    `}
`

const QuestionsWrapper = styled.div`
  margin-top: 20px;

  ${media.lg.min} {
    margin-top: 40px;
  }
`

const StyledCategoryText = styled(Text)`
  padding: 20px 10px;

  ${media.lg.min} {
    padding: 20px;
  }
`

const QuestionsInnerWrapper = styled.div``

const StyledAccordionItemButton = styled(AccordionItemButton)`
  padding: 20px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  img {
    min-width: 16px;
  }

  p {
    padding-right: 10px;
  }

  &[aria-expanded='true'] {
    img {
      transform: rotate(90deg);
    }
  }

  ${media.lg.min} {
    padding: 20px;
    justify-content: flex-start;

    img {
      margin-left: 15px;
    }
  }
`

const StyledAccordionItemPanel = styled(AccordionItemPanel)`
  padding: 20px 10px;

  ${media.lg.min} {
    padding: 20px;
  }

  animation: reveal 0.3s ease-out;

  @keyframes reveal {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`

const StyledAccordionItem = styled(AccordionItem)`
  &:first-child {
    ${StyledAccordionItemButton} {
      border-top: 1px solid ${({ theme }) => theme.colors.black};
    }
  }
`

type QAItem = {
  question: string
  answer: string
}

type Props = {
  qa: {
    ATV: QAItem[]
    UTV: QAItem[]
    SSV: QAItem[]
    Motocykle: QAItem[]
  }
}

const QA: React.FC<Props> = ({ qa }) => {
  const { lg } = useBreakpoint()

  const [category, setCategory] = useState<'ATV' | 'UTV' | 'SSV' | 'Motocykle'>(
    'ATV'
  )

  return (
    <Container>
      <Wrapper>
        <Text transform="uppercase" weight={700} size={lg ? 30 : 18}>
          Wybierz z listy interesującą ciebie kategorię
        </Text>
        <CategoriesWrapper>
          <ButtonWrapper active={category === 'ATV'}>
            <ButtonWithIcon onClick={() => setCategory('ATV')}>
              ATV
            </ButtonWithIcon>
          </ButtonWrapper>

          <ButtonWrapper active={category === 'UTV'}>
            <ButtonWithIcon onClick={() => setCategory('UTV')}>
              UTV
            </ButtonWithIcon>
          </ButtonWrapper>

          <ButtonWrapper active={category === 'SSV'}>
            <ButtonWithIcon onClick={() => setCategory('SSV')}>
              SSV
            </ButtonWithIcon>
          </ButtonWrapper>
          <ButtonWrapper active={category === 'Motocykle'}>
            <ButtonWithIcon onClick={() => setCategory('Motocykle')}>
              Motocykle
            </ButtonWithIcon>
          </ButtonWrapper>
        </CategoriesWrapper>
        <QuestionsWrapper>
          <StyledCategoryText
            transform="uppercase"
            weight={700}
            size={lg ? 30 : 22}
            margin="0"
          >
            {category}
          </StyledCategoryText>
          <QuestionsInnerWrapper>
            <Accordion allowZeroExpanded={true}>
              {qa[category]?.map((item) => (
                <StyledAccordionItem key={item.question}>
                  <AccordionItemHeading>
                    <StyledAccordionItemButton>
                      <Text weight={500} margin="0">
                        {item.question}
                      </Text>
                      <Icon src={arrowIcon} width={16} height={16} />
                    </StyledAccordionItemButton>
                  </AccordionItemHeading>
                  <StyledAccordionItemPanel>
                    {item.answer}
                  </StyledAccordionItemPanel>
                </StyledAccordionItem>
              ))}
            </Accordion>
          </QuestionsInnerWrapper>
        </QuestionsWrapper>
      </Wrapper>
    </Container>
  )
}

export default QA
